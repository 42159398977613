<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    width="700"
    :title="detail && detail.name"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 每日刺激次数 -->
      <a-form-item
        v-if="detail.type === 'cancel_pay_iaa_unlock'"
        label="每日刺激次数"
        name="num"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          placeholder="请选择"
          v-model:value="formState.num"
        >
          <a-select-option :value="1">1次</a-select-option>
          <a-select-option :value="2">2次</a-select-option>
          <a-select-option :value="3">3次</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 次数 -->
      <a-form-item
        v-if="detail.type === 'cancel_pay_iaa_unlock'"
        label="拒绝折扣露出IAA"
        name="status"
        :rules="[{ required: true }]"
      >
        <a-radio-group v-model:value="formState.status">
          <a-radio :value="0">不露出</a-radio>
          <a-radio :value="1">露出</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 次数 -->
      <a-form-item
        v-if="detail.type === 'ad_unlock'"
        label="一次解锁"
        name="num"
        :rules="[{ required: true, validator: validatorNum }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.num"
          suffix="集"
        />
      </a-form-item>
      <!-- 广告参数 -->
      <a-form-item
        label="广告参数"
        name="adid"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入 type,adid，若没有type，直接输入 adid"
          v-model:value="formState.adid"
        />
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import Pub from '@/utils/public'
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import { frontChargeEditAd } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 详情
let detail = ref(null)
// 表单
let formState = reactive({
  // 次数
  num: undefined,
  // 广告参数
  adid: undefined,
  // 状态 0 不露出 1 露出
  status: 0
})


// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // 记录
  detail.value = params
  // 赋值
  nextTick(() => {
    // id
    id.value = params.id
    // 次数
    formState.num = params.num
    // 广告参数
    formState.adid = params.adid
    // 状态
    formState.status = params.status
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    id.value = undefined
    visible.value = false
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    const params = {
      id: id.value,
      unlock_num: formState.num,
      ad_id: formState.adid,
      status: formState.status
    }
    frontChargeEditAd(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

function validatorNum (rule, value) {
  // 否则校验自身
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  }
  return Promise.resolve()
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
</style>