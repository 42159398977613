<template>
  <div class="tool-view">
    <div class="option-box">
      调用：
      <template
        v-for="option in Platforms"
        :key="option.value"
      >
        <!-- 过滤掉当前平台 -->
        <a-checkbox
          :checked="option.value === checkedOption"
          :disabled="isLoading"
          @change="changeCheckedOption(option.value, $event)"
          v-if="option.value !== props.platform"
        >
          {{ option.text }}
        </a-checkbox>
      </template>
    </div>
    <a-button type="primary" @click="handleGeneralSetting"><setting-outlined />通用设置</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :loading="isLoading"
    :pagination="false"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record, index }">
      <!-- 名称 -->
      <template v-if="column.key === 'name'">
        {{ record.name === '虚拟币' ? '用户中心 - 虚拟币' : record.name }}
      </template>
      <!-- 是否开启 -->
      <template v-if="column.key === 'is_on'">
        <a-switch
          checked-children="开"
          un-checked-children="关"
          :disabled="isLoading"
          :checked="!!record.is_on"
          @change="changeSwitch(record, $event)"
        />
      </template>
      <!-- 设置 -->
      <template v-if="column.key === 'detail'">
        <div v-for="(setting, ind) in record.detail" :key="ind" class="play-name">
          <!-- 虚拟币 -->
          <template v-if="setting.type === 'coin'">
            <!-- 第一项 -->
            {{ setting.money }}元
            <span class="gap">|</span>
            <!-- 第二项 -->
            立得{{ setting.coin }}{{ record.project.virtual_coin_name }}
            <!-- 第三项 -->
            <template v-if="setting.free_coin">
              <span class="gap">|</span>
              送{{ setting.free_coin }}{{ record.project.virtual_coin_name }}
            </template>
          </template>
          <!-- 全集解锁 -->
          <template v-if="setting.type === 'unlock'">
            <!-- 第一项 -->
            {{ setting.money }}元
            <!-- 第二项 -->
            <a-tag class="tag-type" v-if="setting.description">辅助语：{{ setting.description }}</a-tag>
          </template>
          <!-- 五折卡、会员、全集解锁 -->
          <template v-else>
            <!-- 第一项 -->
            {{ setting.days }}天<span class="gap">|</span>
            <!-- 第二项 -->
            {{ setting.money }}元
            <!-- 第三项 -->
            <a-tag class="tag-type" v-if="setting.description">辅助语：{{ setting.description }}</a-tag>
          </template>
          <!-- 辅助语 -->
          <a-tag class="tag-type" v-if="setting.is_help_message">辅助语：{{ setting.help_message }}</a-tag>
          <!-- 角标 -->
          <a-tag class="tag-type" v-if="setting.is_corner_on">角标：{{ setting.corner_name }}</a-tag>
          <!-- 气泡 -->
          <a-tag class="tag-type" v-if="setting.is_bubble_on">气泡：{{ setting.bubble_name }}</a-tag>
          <!-- 底色突出 -->
          <a-tag class="tag-type" v-if="setting.is_highlight">底色突出</a-tag>
          <!-- 手势引导 -->
          <a-tag class="tag-type" v-if="setting.is_gesture">手势引导</a-tag>
        </div>
        <!-- 未配置时 -->
        <div v-if="!record.detail.length"> - </div>
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
      <!-- 编辑 -->
        <a-button
          type="link"
          @click="handleEdit(record)"
        >
          编辑
        </a-button>
      </template>
    </template>
  </a-table>
  <!-- 通用设置 -->
  <GeneralSetting ref="RefGeneralSetting" @success="getFrontSourceList"></GeneralSetting>
  <!-- 单个编辑 -->
  <Edit ref="RefEdit" @success="getFrontSourceList"></Edit>
  <!-- 广告解锁剧集 -->
  <AdUnlockDrawer ref="RefAdUnlockDrawer" @success="getFrontSourceList"></AdUnlockDrawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { Platforms, FrontSource } from '@/utils/constantList'
import { message } from 'ant-design-vue'
import GeneralSetting from './GeneralSetting'
import AdUnlockDrawer from './AdUnlockDrawer'
import Edit from './Edit'
import { frontSourceSettingDetail, frontSourceSetting, frontSourceList, frontChargeSwitch, frontChargeSwitchAd } from '@/api/operate'

// 通用设置组件实例
let RefGeneralSetting = ref(null)
// 编辑组件实例
let RefEdit = ref(null)
// 广告解锁剧集
let RefAdUnlockDrawer = ref(null)
// props
const props = defineProps({
  platform: Number
})
// source类型
const source = FrontSource.charge
// 公共设置详情
let settingDetail = ref({})
// 调用项选中状态
let checkedOption = ref([])
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '功能模块',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '是否开启',
    dataIndex: 'is_on',
    key: 'is_on'
  },
  {
    title: '价格设定',
    dataIndex: 'detail',
    key: 'detail'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount(() => {
  // 获取公共配置详情
  getFrontSourceSettingDetail()
  // 获取配置列表
  getFrontSourceList()
})

// 获取公共配置详情
function getFrontSourceSettingDetail () {
  isLoading.value = true
  frontSourceSettingDetail({
    source,
    platform_id: props.platform
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      if (data) {
        // 保存顶部公共设置
        settingDetail.value = data
        // 调用平台
        checkedOption.value = data.use_platform_id
      }
    } else {
      message.error(res.message || msg)
    }
  })
}

// 获取配置列表
function getFrontSourceList () {
  isLoading.value = true
  frontSourceList({
    source,
    platform_id: props.platform
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 设置公共配置
function setFrontSourceSetting (obj = {}) {
  isLoading.value = true
  const params = { 
    source,
    platform_id: props.platform,
    use_platform_id: checkedOption.value,
    // 覆盖项
    ...obj
  }
  frontSourceSetting(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新详情
      getFrontSourceSettingDetail()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 修改调用项勾选状态（单选！选中传id，取消则传自身平台 platform-id）
function changeCheckedOption (id, e) {
  setFrontSourceSetting({
    use_platform_id: e.target.checked ? id : props.platform
  })
}

// 通用设置
function handleGeneralSetting () {
  RefGeneralSetting.value.showDrawer()
}

// 是否开启
function changeSwitch (record, boolean) {
  const params = {
    id: record.id,
    is_on: boolean ? 1 : 0
  }
  if (
    record.type === 'ad_unlock' ||
    record.type === 'iaa_ad_unlock' ||
    record.type === 'again_iaa_ad_unlock' ||
    record.type === 'cancel_pay_iaa_unlock' ||
    record.type === 'close_pay_iaa_unlock'
  ) {
    // 广告解锁
    frontChargeSwitchAd(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        // 更新列表
        getFrontSourceList()
      } else {
        message.error(res.message || msg)
      }
    })
  } else {
    // 其他
    frontChargeSwitch(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        // 更新列表
        getFrontSourceList()
      } else {
        message.error(res.message || msg)
      }
    })
  }
}

// 编辑
function handleEdit (record) {
  if (
    record.type === 'ad_unlock' ||
    record.type === 'iaa_ad_unlock' ||
    record.type === 'again_iaa_ad_unlock' ||
    record.type === 'cancel_pay_iaa_unlock' ||
    record.type === 'close_pay_iaa_unlock'
  ) {
    // 广告解锁剧集
    RefAdUnlockDrawer.value.showDrawer({
      // id
      id: record.id,
      // 名称
      name: record.name,
      // 次数
      num: record.unlock_num,
      // 广告参数
      adid: record.ad_id,
      // 类型
      type: record.type,
      // 状态
      status: record.status
    })
  } else {
    RefEdit.value.showDrawer({
      // id
      id: record.id,
      // 名称
      name: record.name,
      // 虚拟币、五折卡对应的类型
      type: record.type,
      // 当前平台
      platform_id: props.platform,
    })
  }
}

</script>

<style lang="less" scoped>
.tool-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 20px;
}
.table-view {
  :deep(.ant-table-cell) {
    vertical-align: top;
  }
  .play-name {
    white-space: nowrap;
    &:not(:last-child) {
      margin-bottom: 28px;
    }
    .gap {
      margin: 0 8px;
    }
  }
}
a[disabled] {
  pointer-events: none;
  cursor: default;
}
.tag-type {
  margin-left: 20px;
  background: #eee;
}
</style>