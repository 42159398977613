<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="'编辑' + params.name"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 18 }"
    >
      <!-- 模板样式 -->
      <a-form-item
        v-if="params.type === 'coin'"
        label="模板样式"
        name="style_number"
        :rules="[{ required: true }]"
      >
        <a-radio-group v-model:value="formState.style_number" @change="changeType">
          <a-radio :value="4">四宫格</a-radio>
          <a-radio :value="6">六宫格</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 组 -->
      <a-form-item
        :label="'第' + (gindex - 0 + 1) + '组'"
        :rules="[{ required: true }]"
        v-for="(group, gindex) in formState.content"
      >
        <div class="data-list">
          <!-- 单条数据：输入框必填，多选框非必填（多选框勾选，后面的输入框必填） -->
          <a-form-item
            class="play-item"
            v-for="item in keySetting[params.type]"
            :rules="[{ required: (!item.isCheck && (!item.bindCheckKey || group[item.bindCheckKey]) && !item.notRequired), validator: validator }]"
            :name="['content', gindex, item.key]"
            :style="{ width: (item.width || '100%'), marginRight: (item.marginRight || 0) }"
          >
            <!-- 输入框 -->
            <a-input
              :placeholder="item.key === 'description' ? '划线内容请用 [ ] 包裹' : '请输入'"
              v-model:value="group[item.key]"
              :suffix="item.name"
              :maxlength="item.maxlength"
              v-if="!item.isCheck"
            />
            <!-- 气泡、角标：勾选框 -->
            <template v-else>
              <a-checkbox
                v-model:checked="group[item.key]"
                @change="changeCheck(gindex, item.key, $event)"
              >
                {{ item.name }}
              </a-checkbox>
            </template>
          </a-form-item>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import Loading from '@/components/Loading'
import { message } from 'ant-design-vue'
import Pub from '@/utils/public'
import { frontChargeDetail, frontChargeEdit } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 父组件传进来的参数
let params = ref({})
// 四组信息
let formState = reactive({
  // 模板样式（4四宫格 6六宫格）
  style_number: 4,
  // 配置
  content: []
})
// 虚拟币、五折卡、会员（params.type）对应的字段 key
const keySetting = {
  // 虚拟币
 'coin': [
    { key: 'money', name: '价格', width: '32%', marginRight: '2%' },
    { key: 'coin', name: '立得', width: '32%', marginRight: '2%' },
    { key: 'free_coin', name: '再送', width: '32%', notRequired: true },
    { key: 'is_help_message', name: '辅助语', isCheck: true, width: '16%', marginRight: '2%' },
    { key: 'help_message', name: '', bindCheckKey: 'is_help_message', maxlength: 10, width: '82%' },
    { key: 'is_corner_on', name: '角标', isCheck: true, width: '16%', marginRight: '2%' },
    { key: 'corner_name', name: '', bindCheckKey: 'is_corner_on', maxlength: 6, width: '82%' },
    { key: 'is_bubble_on', name: '气泡', isCheck: true, width: '16%', marginRight: '2%' },
    { key: 'bubble_name', name: '', bindCheckKey: 'is_bubble_on', maxlength: 10, width: '82%' },
    { key: 'is_highlight', name: '底色突出', isCheck: true, width: '20%', marginRight: '2%' },
    { key: 'is_gesture', name: '手势引导', isCheck: true, width: '20%', marginRight: '2%' }
 ],
  // 五折卡
 'half_past_card': [
    { key: 'days', name: '天', width: '49%', marginRight: '2%' },
    { key: 'money', name: '价格', width: '49%' },
    { key: 'description', name: '辅助语' },
    { key: 'is_corner_on', name: '角标', isCheck: true, width: '13%', marginRight: '2%' },
    { key: 'corner_name', name: '', bindCheckKey: 'is_corner_on', maxlength: 6, width: '85%' },
    { key: 'is_bubble_on', name: '气泡', isCheck: true, width: '13%', marginRight: '2%' },
    { key: 'bubble_name', name: '', bindCheckKey: 'is_bubble_on', maxlength: 10, width: '85%' },
 ],
  // 会员
 'vip': [
    { key: 'days', name: '天', width: '49%', marginRight: '2%' },
    { key: 'money', name: '价格', width: '49%' },
    { key: 'description', name: '辅助语' },
    { key: 'is_corner_on', name: '角标', isCheck: true, width: '13%', marginRight: '2%' },
    { key: 'corner_name', name: '', bindCheckKey: 'is_corner_on', maxlength: 6, width: '85%' },
    { key: 'is_bubble_on', name: '气泡', isCheck: true, width: '13%', marginRight: '2%' },
    { key: 'bubble_name', name: '', bindCheckKey: 'is_bubble_on', maxlength: 10, width: '85%' },
 ],
}

// 默认模板值
const defaultVals = {
  'coin': {
    project_id: Pub.CUSTOM('project'),
    // 模板类型
    type: 'coin',
    // 金额
    money: undefined,
    // 立得
    coin: undefined,
    // 再送
    free_coin: undefined,
    // 辅助语勾选
    is_help_message: false,
    help_message: undefined,
    // 角标勾选
    is_corner_on: false,
    corner_name: undefined,
    // 气泡勾选
    is_bubble_on: false,
    bubble_name: undefined,
    // 底色突出
    is_highlight: false,
    // 底色突出
    is_gesture: false,
    // 平台
    platform_id: 0
  }
}

// 打开抽屉
function showDrawer (obj) {
  // 展开
  visible.value = true
  // 参数保存
  params.value = obj
  // 获取配置详情
  getFrontChargeDetail()
}

// 切换四六宫格
function changeType () {
  initTemplate(formState.style_number)
}

// 初始化模板（默认是虚拟币模板）
function initTemplate (loop, type = 'coin') {
  // 重置
  // formState.content = []
  // // 表单值初始化
  // nextTick(() => {
  //   for(let i = 0; i < loop; i++) {
  //     formState.content[i] = Pub.deepClone(defaultVals[type])
  //   }
  //   console.log(formState.content)
  // })
  // 间距差
  const num = Math.abs(formState.content.length - loop)
  // 是否多了
  if (formState.content.length > loop) {
    // 多了
    for (let index = 0; index < num; index++) {
      formState.content.pop()
    }
  } else {
    // 少了
    for (let index = 0; index < num; index++) {
      formState.content.push(Pub.deepClone(defaultVals[type]))
    }
  }
}


// 切换气泡角标勾选框
function changeCheck (gindex, key, e) {
  // 限制气泡在一个模板中只能勾选一个
  if (key === 'is_bubble_on') {
    const bool = e.target.checked
    if (bool) {
      // 选中时，把其他组选中重置掉
      formState.content.forEach((item, index) => {
        if (index !== gindex) {
          item.is_bubble_on = false
        }
      })
    }
  }
}

// 获取配置详情
function getFrontChargeDetail () {
  isLoading.value = true
  frontChargeDetail({
    id: params.value.id
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 列表
      const list = data.charge_details
      if (list.length) {
        // 有数据
        list.forEach(group => {
          for(let i in group) {
            if (i === 'is_corner_on' || i === 'is_help_message' ||  i === 'is_bubble_on' ||  i === 'is_highlight' ||  i === 'is_gesture') {
              // 处理气泡、角标选中值为布尔值
              group[i] = !!group[i]
            }
          }
        })
        // 赋值
        formState.content = list
      } else {
        // 无数据，需要根据类型初始化指定数据
        // 当前类型需要编辑的 key
        const keys = keySetting[params.value.type].map(item => item.key)
        // 指定循环四组
        for(let i = 0; i < 4; i++) {
          // 初始化每一组为{}格式
          formState.content[i] = {}
          // 循环 key
          keys.forEach(item => {
            // 填充 key
            formState.content[i][item] = (item === 'is_corner_on' || i === 'is_help_message' || item === 'is_bubble_on' ? false : undefined)
          })
          // 类型也需要填充进每一项
          formState.content[i]['type'] = params.value.type
        }
      }
      // 四六宫格
      formState.style_number = data.charge.style_number
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 表单重置
    RefForm.value.resetFields()
    visible.value = false
    formState.content = []
  }
}

// 表单校验
function validator (rule, value) {
  // 再送（非必填）
  if (rule.field.includes('free_coin')) {
    if (!Pub.REG_IS_POSITIVE_INTEGER(value) && value !== undefined && value !== '') {
      return Promise.reject('必须为正整数')
    }
    return Promise.resolve()
  }
  // 立得、天数、再送
  else if (rule.field.includes('coin') || rule.field.includes('days')) {
    if (!value) {
      return Promise.reject('请输入')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('必须为正整数')
    }
    return Promise.resolve()
  }
  // 价格
  else if ( rule.field.includes('money')) {
    if (!value) {
      return Promise.reject('请输入${label}')
    } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
      return Promise.reject('必须为正数，且最多保留两位小数')
    }
    return Promise.resolve()
  }
  // 辅助语、角标、气泡
  else if (rule.field.includes('description') || rule.field.includes('corner_name') || rule.field.includes('help_message')  || rule.field.includes('bubble_name')) {
    if (!value && rule.required) {
      return Promise.reject('请输入${label}')
    }
    return Promise.resolve()
  }
  return Promise.resolve()
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    // 复制一份数据处理选中值为 0 、1
    let copyData = Pub.deepClone(formState.content)
    copyData.forEach(group => {
      for(let i in group) {
        if (i === 'is_corner_on' || i === 'is_help_message' ||  i === 'is_bubble_on' ||  i === 'is_highlight' ||  i === 'is_gesture') {
          // 处理气泡、角标选中值为布尔值
          group[i] = group[i] ? 1 : 0
        }
        if ((i === 'bubble_name' || i === 'corner_name' || i === 'help_message') && !group[i]) {
          // 处理气泡、角标内容（undefined会被过滤）
          group[i] = ''
        }
      }
    })
    // 参数准备
    const query = {
      // 模版ID
      id: params.value.id,
      // 自定义模板，无平台值
      platform_id: params.value.platform_id,
      // 虚拟币类型
      type: params.value.type,
      // 宫格
      style_number: formState.style_number,
      // 列表
      content: copyData,
    }
    frontChargeEdit(query).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-item-clear {
  margin-bottom: 0;
}
.data-list {
  display: flex;
  flex-wrap: wrap;
}
.play-item {
  margin-bottom: 16px;
}
.ant-btn-link {
  padding: 0;
  margin-left: 8px;
}
</style>