<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    title="通用设置"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }"
    >
      <!-- 板块名称 -->
      <a-form-item
        label="虚拟币名称"
        name="virtual_coin_name"
        :rules="[{ required: true, message: '请输入虚拟币名称' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.virtual_coin_name"
          :maxlength="10"
        />
      </a-form-item>
      <!-- 付费起始 -->
      <a-form-item
        label="付费起始"
        name="start_pay_eq"
        :rules="[{ required: true, validator: validator }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.start_pay_eq"
        />
      </a-form-item>
      <!-- 每集收费 -->
      <a-form-item
        label="每集收费"
        name="fee"
        :rules="[{ required: true, validator: validator }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.fee"
        />
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import Loading from '@/components/Loading'
import { message } from 'ant-design-vue'
import Pub from '@/utils/public'
import { frontChargeGeneralSettingDetail, frontChargeGeneralSettingEdit } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 剧集信息
let formState = reactive({
  // 虚拟币名称
  virtual_coin_name: undefined,
  // 付费起始
  start_pay_eq: undefined,
  // 每集收费
  fee: undefined
})

// 打开抽屉
function showDrawer () {
  // 展开
  visible.value = true
  // 获取通用配置详情
  getFrontChargeGeneralSettingDetail()
}

// 获取配置详情
function getFrontChargeGeneralSettingDetail () {
  isLoading.value = true
  frontChargeGeneralSettingDetail().then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 虚拟币名称
      formState.virtual_coin_name = data.virtual_coin_name
      // 付费起始片集
      formState.start_pay_eq = data.start_pay_eq
      // 每集收费
      formState.fee = data.fee
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 表单重置
    RefForm.value.resetFields()
    visible.value = false
  }
}

// 表单校验
function validator (rule, value) {
  if (rule.field === 'start_pay_eq' || rule.field === 'fee') {
    if (!value) {
      return Promise.reject('请输入${label}')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('必须为正整数')
    }
    return Promise.resolve()
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    const params = {
      ...formState
    }
    frontChargeGeneralSettingEdit(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-item-clear {
  margin-bottom: 0;
}
.play-item {
  margin-bottom: 16px;
}
.ant-btn-link {
  padding: 0;
  margin-left: 8px;
}
</style>